





























































  import Vue from 'vue';
  import Component, {mixins} from 'vue-class-component';
  import dataFormat from '@/shared/utils/dataFormat';
  import PaymentInfo from '@/components/deposit/PaymentInfo.vue';
  import {Prop} from 'vue-property-decorator';
  import {paymentModule, placeModule} from '@/store';
import { Notify } from 'quasar';

  @Component({ components: { PaymentInfo }})
  export default class ReserveCancel extends mixins(dataFormat) {
    @Prop()
    public reservation!: any;
    @Prop()
    public selectLang!: any;
    @Prop()
    public type!: any;
    @Prop()
    public product!: any;

    public agree: boolean = false;
    public btnLoading: boolean = false;

    // 예약 취소
    public cancel() {
      this.btnLoading = true;
      if (this.type === 'place') {
        const params = {
          id: this.reservation.id,
          refund_rate: this.reservation.order.refund_info.refund_rate
        };
        placeModule.deleteCancel(params).then((res) => {
          console.log(res);
          if (res.status) {
            Notify.create({
              message: '예약이 취소되었습니다. 상품취소규정에 따라 환불이 처리됩니다.'
            });
            this.close();
          } else {
            if (res.error.code === 725) location.reload();
            Notify.create({
              message: res.error.message
            });
            this.close();
          }
          this.btnLoading = false;
        });
      } else {
        paymentModule.cancelReservation(this.$route.params.reservationId).then((res) => {
          if (res.code === 20000) {
            paymentModule.fetchReservationSetting(this.$route.params.reservationId);
            this.close();
          } else {
            this.$q.notify(res.data);
            this.close();
          }
          this.btnLoading = false;
        })
      }
    }

    public close() {
      this.agree = false;
      this.$emit('refresh', this.reservation.id);
      document.querySelector('html' as any).classList.remove('open-modal');
      this.$modal.hide('reserveCancel');
    }
  }
